<template>
  <div id="placement-index">
    <KCrudLayout :with-search="false">
      <template #header>
        {{ $tc('placement.title', 2) }}
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          language-prefix="placement.fields"
          resource="placement"
        >
          <template #item.isActive="{ item }">
            <v-icon
              v-if="item.isActive"
              color="success"
            >
              $circle
            </v-icon>
          </template>
          <template #item.startDate="{ item }">
            {{ dayjs(item.startDate, 'YYYY-MM-DD').format('LL') }}
          </template>
          <template #item.endDate="{ item }">
            <span v-if="item.endDate">
              {{ dayjs(item.endDate, 'YYYY-MM-DD').format('LL') }}
            </span>
            <span v-else>{{ $t('placement.indefinitely') }}</span>
          </template>
          <template #item.grossHourlyWage="{ item }">
            {{ $n(parseInt(item.grossHourlyWage) / 100, 'currency') }}
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { index } from '@/modules/placement/api/placement.js';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'Placements',
  components: {
    KCrudTable,
    KCrudLayout,
  },
  computed: {
    crudHeaders() {
      return [{
        text: '',
        value: 'isActive',
        align: 'center',
        sortable: false,
        filterable: false,
        width: '1%',
        cellClass: 'no-table-separator',
      },
      {
        value: 'candidateName',
        language: 'placement.crudHeaders.candidateName',
        cellClass: 'no-table-separator',
      },
      {
        value: 'employerName',
        language: 'placement.crudHeaders.employerName',
      },
      {
        value: 'positionName',
        language: 'placement.crudHeaders.positionName',
      },
      {
        value: 'grossHourlyWage',
        language: 'placement.crudHeaders.rate',
        sortable: false,
      },
      {
        value: 'startDate',
        language: 'placement.crudHeaders.startDate',
      },
      {
        value: 'endDate',
        language: 'placement.crudHeaders.endDate',
      }];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'placement.index' },
        text: this.$tc('placement.title', 2),
      },
    ]);
  },
  methods: {
    dayjs,
    indexRequest() {
      return index(...arguments);
    },
  },
};
</script>
